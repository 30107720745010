import { EvaluationContext } from '../evaluation'
import { Value } from '../types'
import { StringTransformer } from './'

/**
 * Shortcut to evaluate an expression using the ":not" method.
 *
 * @usage: ```! expression```
 */
class NotStringTransformer implements StringTransformer {
  test(expr: any): boolean {
    const firstCharCode = expr.charCodeAt(0)
    return firstCharCode === 33 /* ! */
  }

  evaluate(this: EvaluationContext, expr: string): Value {
    const value = this.evalStringExpression(expr.slice(1).trimStart())

    return !value
  }
}

export default new NotStringTransformer()
